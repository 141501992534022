import * as firebase from "firebase/app";
import "firebase/auth";

// Make sure it hasn't already been initialized
if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyAAFmvFXACGfu2-gJYrCjL4hCLt7Pzntw8",
    authDomain: "happydebits.firebaseapp.com",
    projectId: "happydebits",
    appID: "1:450552845336:web:113ee1f397f3c15ec35f6d"
  });
}

export default firebase;
