import React,{useState} from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import 'react-dropdown/style.css'

function PricingComingSoonSection(props) {

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={false}
          className="text-center"
        />
       
      </Container>
    </Section>
  );
}

export default PricingComingSoonSection;
