import React, {useState,useEffect} from 'react';
import TableWrapper from "./../components/Table";
import Form from "./../components/Form";
import NavbarCustom from "./../components/NavbarCustom";
import { useAuth,requireAuth } from '../util/auth';
import firebase from "./../util/firebase";
import {onSort,toggleSort,fetchDataFromServer} from "./../util/subUtils";
import { Spinner } from 'react-bootstrap'
import { useUser, requireSubs } from '../util/payments';

function ManagePage(props){
    const auth = useAuth();
    const userData = useUser();
    let subscriptions = userData.subs;
    if(subscriptions===null){
      subscriptions=[];
    }
    const [isLoading, setIsLoading] = useState(false);
    const [isMounted, setIsMounted] = useState(false)
    const [sortState, setSortState] = useState({
      AmountSort: "ascending",
      nameSort: "ascending",
      isInitialSort: true
    });
  
    const toggleAndSaveSort = async (event, keyName) => {
      let newSortState = toggleSort(sortState,sortState,keyName);
      const sortedSubs = onSort(event, keyName, sortState, [...subscriptions])
      subscriptions = sortedSubs;
      await save();
      setSortState(newSortState);
    }

    const loadingCallback = () => {
      setIsLoading(true);
    }

    const handleSubmit = async subscription => {
      subscriptions = [...subscriptions, subscription];
      await save();
    }

    const removeSubscription = async index => {
      const newsub = subscriptions.filter((subscription, i) => {
        return i !== index;
      })
      subscriptions = newsub;
      await save();
    }

    const editSub = async (subscription, index) => {
      const newSubs  = Array.from(subscriptions)
      newSubs[index] = subscription
      //emailVerified = auth.user.emailVerified;
      subscriptions=newSubs;
      await save();
    }

    useEffect(() => { 
    }, []);

    const save = async () => {
        if(auth && auth.user && userData){
          try{
            await userData.saveUserSubs(subscriptions,auth,loadingCallback);
            setIsLoading(false);
          }catch(e){
            if(process.env.NODE_ENV !== "production"){
              console.log(e);
            }
          }
        }
    }

    let displayData = "";
    if (subscriptions===undefined || subscriptions.length === 0) {
      displayData = <div className="margin-bottom margin-top"><b>Get started by adding new subscriptions below</b></div>;
    } else {
      displayData = <><h5 className="margin-1-all">Your Subscriptions</h5> <TableWrapper toggleAndSaveSort = {toggleAndSaveSort} isManageMode={true} subscriptionData={subscriptions} removeSubscription={removeSubscription} editSub = {editSub} sortState={sortState}/></>;
    }
    let loadingContent =<> <div className="SpinnerContainer vertical-center justify-content"><Spinner className="SpinnerMargin" animation="border" role="status"/></div><span className="sr-only">Loading...</span></>
    let mainContent = <>{displayData} <Form handleSubmit={handleSubmit} /></>
    if(isLoading){
      mainContent = loadingContent
    }
    return (
      <>
      <NavbarCustom
        bg="primary"
        variant="light"
        expand="md"
        logo="assets/HappyDebits.png"
      ></NavbarCustom>
      
      <div className="container">
        {mainContent}
        </div>
      </>
    );
}

export default requireAuth(requireSubs(ManagePage));