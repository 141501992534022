import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap'
import NavbarCustom from "./../components/NavbarCustom";
import HeroSection2 from "../components/HeroSection2";
import BillingControlSection from "../components/BillingControlSection";
import Button from "react-bootstrap/Button";
import { useAuth, requireAuth } from '../util/auth';
import { fetchDataFromServer } from "./../util/subUtils";
import firebase from "./../util/firebase";
import { useUser, requireSubs } from '../util/payments';


function BillingPage(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false)
  const userData = useUser();
  let billing = userData.billing;
  if(billing===null){
    billing=[];
  }
  
  const apiRequest = async (shouldHideLoading, isLoadingNeeded, postData, action, methodType) => {
    if (isLoadingNeeded) {
      setIsLoading(true);
    }
    try {
      let idToken = await firebase.auth().currentUser.getIdToken();
      let result = await fetchDataFromServer(idToken, postData, action, methodType);
      if (result != undefined) {
        setIsLoading(false);
        return result;
      } else {
        alert("Try again. Error: Please check your network connection and refresh the page.")
      }
    } catch (error) {
      if (process.env.NODE_ENV !== "production") {
        console.log("error in getIdToken")
      }
    }
  }

  let loadingContent =<> <div className="SpinnerContainer vertical-center justify-content"><Spinner className="SpinnerMargin" animation="border" role="status"/></div><span className="sr-only">Loading...</span></>
  let mainContent = <BillingControlSection apiRequest={apiRequest} userData={billing}></BillingControlSection>;
  if(isLoading){
    mainContent = loadingContent
  }

  return (
    <>
      <NavbarCustom
        bg="primary"
        variant="light"
        expand="md"
        logo="assets/HappyDebits.png"
      ></NavbarCustom>
      <HeroSection2
        bg="white"
        textColor="dark"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="Billing"
        subtitle="You can upgrade, downgrade, cancel your Happydebits subscription here"
      />
      {mainContent}
      
    </>
  );
}

export default requireAuth(requireSubs(BillingPage, true));
