import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import Button from "react-bootstrap/Button";
import Toggle from 'react-toggle'

function ModalLauncher(props) {
  const [show, setShow] = useState(false);
  const [date, setDate] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState(props.subscription);
  const [isToggleModified, setIsToggleModified] = useState(false);
  const [isDateModified, setIsDateModified] = useState(false);
  useEffect(() => {
    setCurrentSubscription({...props.subscription})
    setIsToggleModified(false)  
    setIsDateModified(false)
  }, [])
  
  const handleChange = function(event) {
    if(!currentSubscription.isEmailReminderEnabled || currentSubscription.isEmailReminderEnabled !== event.target.checked){
      setIsToggleModified(true);
    }else{
      setIsToggleModified(false);
    }
    currentSubscription.isEmailReminderEnabled = event.target.checked;
    setCurrentSubscription(currentSubscription);
  }

  const handleDateChange = function(event) {
    if(!currentSubscription.renewalDate || JSON.stringify(currentSubscription.renewalDate)!== event.target.value){
        setIsDateModified(true);
    }else{
      setIsDateModified(false);
    }
    currentSubscription.renewalDate = event.target.value;
    setCurrentSubscription(currentSubscription);
  }

    return (
    <> 
      <button className = {"margin-half-LR "+(props.subscription.isEmailReminderEnabled ? "active-reminder" : "")} title="Set email renewal reminder" onClick={() => setShow(true)}>
      <FontAwesomeIcon icon={faBell} /></button> 

      <Modal
        show={show}
        onHide={() => setShow(false)}
        container={document.body}
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>Set renewal reminder email for {currentSubscription.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <input type="date" value={currentSubscription.renewalDate || ''} name="name"
                            onChange={handleDateChange}></input>
          <br></br>
          Enter the renewal date of your subscription. One day before your renewal date, you will be receiving a reminder email. This helps you cancel a free trial or a subscription that you plan to cancel.
          <br></br>
          <div className="margin-1-all flex-display margin-bottom">
          <Toggle 
            className=" inline-block margin-right-1"
            id='renewal-reminder'
            aria-labelledby='renewal-reminder-status'
            defaultChecked={currentSubscription.isEmailReminderEnabled}
            onChange={handleChange} 
          />
          <span id='renewal-reminder-status' className="inline-block margin-right-1">Renewal reminder status</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="md" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="secondary" size="md" onClick={() => {
            if(isToggleModified || isDateModified){
               props.editSub(currentSubscription, props.index);
            }else{
            }
            setShow(false)
          }}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalLauncher;
