import React from "react";
import SettingsSection from "../components/SettingsSection";
import NavbarCustom from "../components/NavbarCustom";
import { requireAuth } from '../util/auth';


function PasswordSettingsPage(props) {
  return (
    <>
    <NavbarCustom
        bg="primary"
        variant="light"
        expand="md"
        logo="/assets/HappyDebits.png"
      ></NavbarCustom>
    <SettingsSection
      bg="white"
      textColor="dark"
      size="md"
      bgImage=""
      bgImageOpacity={1}
      section="password"
      key="general"
    ></SettingsSection>
    </>
  );
}

export default requireAuth(PasswordSettingsPage);
