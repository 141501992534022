import React from "react";
import PricingSection from "./../components/PricingSection";
import PricingComingSoonSection from "./../components/PricingComingSoonSection";
import NavbarCustom from "./../components/NavbarCustom";
import { history } from "./../util/router";

function PricingPage(props) {

  const notifyPaidPlans = () => {
    window.location.href = "https://www.myrenewals.live";
  }

  return (
    <>
      <NavbarCustom
        bg="primary"
        variant="light"
        expand="md"
        // logo="https://uploads.divjoy.com/logo.svg"
        logo="assets/HappyDebits.png"
      ></NavbarCustom>
      <PricingComingSoonSection title = "Additional features on premium plans coming soon" subtitle="Thanks for your interest. Get notified on launch by signing up below. "/>
      <div className="text-center">
          <button type="submit" className="justify-content-center align-items-center mybutton" onClick={() => {
            notifyPaidPlans();
          }}>
              Notify me on launch!
          </button>
          <br></br>
          {/* <div className="margin-top">Learn more <a href="https://www.myrenewals.live">here</a></div> */}
      </div>
      {/* <PricingSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Pricing"
        subtitle="Get 2 months for free on annual subscription plans."
      /> */}
    </>
  );
}

export default PricingPage;
