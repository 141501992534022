// import firebase from "./../util/firebase";
import { useUser, clearUserData } from '../util/payments';

const clearAndSignOut = (auth, user) => {
    
    clearUserData(auth, user);
    auth.signout();
};


export {clearAndSignOut};
