import React, { useState,Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortAlphaDown, faSortAlphaDownAlt, faSortAmountDownAlt, faSortAmountDown, faSort, faTrash, faBell, faPencilAlt, faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import Table from 'react-bootstrap/Table';
import ModalLauncher from "./../components/ModalLauncher";
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'

const trashIcon = <FontAwesomeIcon icon={faTrash} />
const TableHeader = props => {
  let nameSorter = faSort;
  let amountSorter = faSort;

  let nameMode = props.sortState.nameSort;
  let amountMode = props.sortState.AmountSort;

  const isInitialSort = props.sortState.isInitialSort;
  if (isInitialSort === "nameSort") {
    amountSorter = faSort;
    if (nameMode === "ascending")
      nameSorter = faSortAlphaDownAlt
    else if (nameMode === "descending")
      nameSorter = faSortAlphaDown
  } else if (isInitialSort === "AmountSort") {
    nameSorter = faSort;
    if (amountMode === "ascending")
      amountSorter = faSortAmountDownAlt
    else if (amountMode === "descending")
      amountSorter = faSortAmountDown
  }

  const AZiconElement = <FontAwesomeIcon title="Sort by subscription name" icon={nameSorter} />
  const NOiconElement = <FontAwesomeIcon title="Sort by amount" icon={amountSorter} />

  let renewalTypeColumn = <th>Renewal type</th>
  let actionsColumn;
  if (props.isManageMode) {
    actionsColumn = <th>Actions</th>;
  } else {
    actionsColumn = "";
  }
  return (
    <thead>
      <tr>
        <th onClick={e => props.toggleAndSaveSort(e, 'name')}>Subscription {AZiconElement}</th>
        <th onClick={e => props.toggleAndSaveSort(e, 'Amount')}>Amount {NOiconElement}</th>
        {(props.isManageMode) ? renewalTypeColumn : null}
        {actionsColumn !== "" ? actionsColumn : null}
      </tr>
    </thead>
  );
}

  const TableBody = props => {

    const [editSubscription, setEditSubscription] = useState({});
    const [editIndex, setEditIndex] = useState(-1);

    const options = [
        'Monthly', 'Annual'
    ]
    const onRenewalType = option => {
      let newsub = {...editSubscription};
      newsub.renewal = option.value;
      setEditSubscription(newsub)
    }
    const handleChange = event => {
      let { name, value } = event.target;
      /* if (value === "") {
          this.setState({
              error: "Subscription name and amount can't be empty"
          });
      } */
      if (name === "Amount" && value === event.target.value) {
          //if (this.state.error == "") {
              value = parseFloat(event.target.value);
              let newsub = {...editSubscription};
              newsub.Amount=value;
              setEditSubscription(newsub)
          //}
      } else {
        let newsub = {...editSubscription};
        newsub.name=value;
        setEditSubscription(newsub);
      }
    }

    const clearEditSub = () => {
      setEditSubscription({});
      setEditIndex(-1);
    }
    const defaultOption = options[0]
    
    let total = 0;
    let isAnnual = props.isAnnual;
    let rows = props.subscriptionData.map((sub, index) => {
      let isEditSubMode = false;
      if(editIndex === index){
        isEditSubMode = true;
      }
      let displayAmount = sub.Amount;
      if (isAnnual && sub.renewal === 'Annual' || (!isAnnual && sub.renewal === 'Monthly')) {
          total += sub.Amount;
        } else if (isAnnual && sub.renewal === 'Monthly') {
          displayAmount = (sub.Amount * 12);
          total += displayAmount;
        } else if (!isAnnual && sub.renewal === 'Annual') {
          displayAmount = (sub.Amount / 12)
          total += displayAmount;
        }
        let actionsColumn;
        if (props.isManageMode) {
          if(isEditSubMode){
            actionsColumn = <td>
            <button className="margin-half-LR" title="Save subscription" onClick={() => {props.editSub(editSubscription, index); clearEditSub();} } ><FontAwesomeIcon icon={faSave} /></button>
            <button className="delButton margin-half-LR" title="Discard changes" onClick={() => clearEditSub() } ><FontAwesomeIcon icon={faTimes} /></button>
          </td>;
          }else{
            actionsColumn = <td>
            <button className="margin-half-LR" title="Edit subscription" onClick={() => { let copiedSub = JSON.parse(JSON.stringify(sub)); setEditSubscription(copiedSub); setEditIndex(index);}} ><FontAwesomeIcon icon={faPencilAlt} /></button>
            {/* <ModalLauncher subscription={sub} editSub={props.editSub} index={index} ></ModalLauncher> */}
            <button className="delButton margin-half-LR" title="Delete subscription" onClick={() => props.removeSubscription(index)}><FontAwesomeIcon  icon={faTrash} /></button>
          </td>;
          }
        } else {
          actionsColumn = "";
        }
        let nameCol = <td>{sub.name}</td>
        if(isEditSubMode){
          nameCol = <td><input
                    className="whiteBGInput"
                    type="text"
                    name="name"
                    value={editSubscription.name}
                    onChange={handleChange} /> </td>
        }
        let amountCol = <td>{displayAmount.toFixed(2)}</td>
        if(props.isManageMode){
          amountCol = <td>{sub.Amount}</td>
        }
        if(isEditSubMode){
          amountCol = <td><input
                          className="whiteBGInput"
                          type="number"
                          name="Amount"
                          value={editSubscription.Amount}
                          onChange={handleChange} /> </td>
        }
        let renewalCol = (props.isManageMode) ? <td>{sub.renewal}</td> : null
        if(isEditSubMode){
          renewalCol = <td><Dropdown options={options} onChange={onRenewalType} value={editSubscription.renewal} placeholder="Select Renewal type" /> </td>
        }
        return (
          <tr key={index}>
            {nameCol}
            {amountCol}
            {renewalCol}
            {actionsColumn !== "" ? actionsColumn : null}
          </tr>
        );
    });
    if (props.isManageMode) {
      /* rows.push(<tr key="total">
        <td><b>Total</b></td>
        <td colSpan="2"><b>{total.toFixed(2)}</b></td>
      </tr>); */
    } else {
      rows.push(<tr key="total">
        <td><b>Total</b></td>
        <td><b>{total.toFixed(2)}</b></td>
      </tr>);
    }

    return <tbody>{rows}</tbody>;
}

class TableWrapper extends Component {
  render() {
    const { subscriptionData, removeSubscription, isAnnual, isManageMode, editSub } = this.props;
    return (
      <Table striped bordered hover>
        <TableHeader isManageMode={isManageMode} toggleAndSaveSort={this.props.toggleAndSaveSort} sortState={this.props.sortState} />
        <TableBody editSub={editSub} isManageMode={isManageMode} subscriptionData={subscriptionData} removeSubscription={removeSubscription} isAnnual={isAnnual} />
      </Table>
    );
  }
}

export default TableWrapper;
