import firebase from "./../util/firebase";

var apiUrl = process.env.apiUrl;
//var apiUrl = "http://localhost:8888"
let context = process.env.REACT_APP_CONTEXT;
if (context==="production"){
  apiUrl = process.env.REACT_APP_URL;
}else if(context==="deploy-preview"){
  apiUrl=process.env.REACT_APP_DEPLOY_URL;
}else if(context==="branch-deploy"){
  apiUrl=process.env.REACT_APP_DEPLOY_PRIME_URL;
}
console.log("context: ",process.env.REACT_APP_CONTEXT) 
console.log("url: ",process.env.REACT_APP_URL) 
console.log("DEPLOY_URL: ",process.env.REACT_APP_DEPLOY_URL) 
console.log("REACT_APP_DEPLOY_PRIME_URL: ",process.env.REACT_APP_DEPLOY_PRIME_URL) 

const apiRequest = async (shouldHideLoading, loadingCallback, postData, action, methodType) => {
  if(loadingCallback){
    loadingCallback();
  }
  try{
    let idToken = await firebase.auth().currentUser.getIdToken();
    let result = await fetchDataFromServer(idToken, postData,action,methodType);
    if(result!=undefined){
      return result;
    }else{
      alert("Try again. Error: Please check your network connection and refresh the page.")
    }
  }catch(error){
      if(process.env.NODE_ENV !== "production"){
        console.log("error in getIdToken")
      }
  }
}

const onSort = (event, sortKey, mode, sortedSubscriptions) => {
  const stateKeyName = sortKey + "Sort";
  mode = mode[stateKeyName]
  if (sortKey === "name") {
    if (mode === "descending")
    sortedSubscriptions.sort((a, b) => (a[sortKey].toString()).localeCompare(b[sortKey]).toString())
    else if (mode === "ascending")
    sortedSubscriptions.sort((a, b) => (b[sortKey].toString()).localeCompare(a[sortKey]).toString())
  }
  else if (sortKey === "Amount") {
    if (mode === "descending")
    sortedSubscriptions.sort((a, b) => getAnnualAmount(b[sortKey], b["renewal"]) - getAnnualAmount(a[sortKey],a["renewal"]))
    else if (mode === "ascending")
    sortedSubscriptions.sort((a, b) => getAnnualAmount(a[sortKey],a["renewal"]) - getAnnualAmount(b[sortKey],b["renewal"] ))
  }
  return sortedSubscriptions;
}
const toggleSort = (currentSortMode,sortState,keyName) => {
  const stateKeyName = keyName + "Sort";
  currentSortMode = currentSortMode[stateKeyName];
  if (currentSortMode === "ascending") {
    sortState[stateKeyName] = "descending";
  } else if (currentSortMode === "descending") {
    sortState[stateKeyName] = "ascending";
  }
  sortState.isInitialSort = stateKeyName;
  return sortState;
}
 
const getAnnualAmount = (amount ,renewalMode) => {
  if(renewalMode==="Monthly")
    return amount*12;
  else
    return amount;
}

const fetchDataFromServer = async (idToken, postData,action, methodType) => {
  let url = apiUrl+"/.netlify/functions/index/"+action;
  let params = {};
  params.headers =  {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'auth': idToken
  };
  params.method = methodType;
  if(postData){
    params.body = JSON.stringify(postData);
  }
   let result = await fetch(
    url,
    params
  ) 
  if(result.status==200){
    return result;
  }else{
    return undefined;
  }
}

export {onSort,toggleSort,fetchDataFromServer,apiRequest};
