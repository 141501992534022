import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";
import { useAuth } from "./../util/auth.js";
import { useUser} from '../util/payments';
import { useRouter } from "./../util/router.js";
import { clearAndSignOut } from "./../util/userUtils.js";

function NavbarCustom(props) {
  const auth = useAuth();
  const user = useUser();
  const router = useRouter();

  return (
    <Navbar bg={props.bg} variant={props.variant} expand={props.expand}>
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>
            <img
              className="d-inline-block align-top"
              src={props.logo}
              alt="Happydebits"
              height="40"
            ></img>
          </Navbar.Brand>
        </LinkContainer>

        <Navbar.Toggle
          aria-controls="navbar-nav"
          className="border-0"
        ></Navbar.Toggle>
        <Navbar.Collapse id="navbar-nav" className="justify-content-end">
          <Nav className="mr-1">
            {auth.user && (
               <> <LinkContainer to="/dashboard">
                  <Nav.Link>Dashboard</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/manage">
                  <Nav.Link>Manage</Nav.Link>
                </LinkContainer> </>
             )}
             {!auth.user && (
              <LinkContainer to="/pricing">
                <Nav.Link>Pricing</Nav.Link>
              </LinkContainer>
            )}
            {!auth.user && (
              <LinkContainer to="/auth/signin">
                <Nav.Link>Sign in</Nav.Link>
              </LinkContainer>
            )}
            

            {auth.user && (
              <NavDropdown id="dropdown" title="Account" alignRight={true}>

                <LinkContainer to="/settings/general">
                  <NavDropdown.Item>
                    Settings
                  </NavDropdown.Item>
                </LinkContainer>
                  {/* Hide for paid users */}
                <LinkContainer to="/pricing">
                  <NavDropdown.Item>
                    Upgrade
                  </NavDropdown.Item>
                </LinkContainer>
                  {/* Only for paid users */}
                <LinkContainer to="/billing">
                  <NavDropdown.Item>
                    Billing
                  </NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/auth/signout">
                  <NavDropdown.Item
                    onClick={e => {
                      e.preventDefault();
                      clearAndSignOut(auth, user)
                      router.push("/");
                    }}
                  >
                    Sign out
                  </NavDropdown.Item>
                </LinkContainer>
                
              </NavDropdown>
            )}
          </Nav>

          {!auth.user && (
            <Button
              variant="primary"
              onClick={() => {
                router.push("/auth/signup");
              }}
            >
              Sign Up
            </Button>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarCustom;
