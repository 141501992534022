import React, {
  useState,
  useEffect,
  useContext,
  createContext,
} from "react";
import { history } from "./router";
import PageLoader from "./../components/PageLoader";
import {apiRequest} from "./../util/subUtils";
import { useAuth } from '../util/auth';

const userContext = createContext();
export function ProvideUser({ children }) {
  const user = useProvideUser();
  return <userContext.Provider value={user}>{children}</userContext.Provider>;
}

export const useUser = () => {
  return useContext(userContext);
};

function useProvideUser() {
  const [subs, setSubs] = useState(null);
  const [billing, setBilling] = useState(null);
  const handleSubs = async (response) => {
    setSubs(response);
    return response;
  };
  const handleBilling = async (response) => {
    setBilling(response);
    return response;
  };

  function getPostDataForSync(userData, auth) {
    let postData = {};
    postData.subscriptions = userData;
    postData.userName = auth.user.name;
    postData.emailAddress = auth.user.email;
    return postData;
  }

  const saveUserSubs = async (userData, auth,loadingCallback ) => {
    setSubs(userData)
    const postData = getPostDataForSync(userData, auth);
    try{
      let res = await apiRequest(true,loadingCallback,postData,"saveUserSubs","post");
      //res = await res.json();
    }catch(e){
      if(process.env.NODE_ENV !== "production"){
        console.log(e);
      }
    }
  }
  
  return {
    subs,
    billing,
    handleSubs,
    handleBilling,
    saveUserSubs
  };
}

// A Higher Order Component for paid plan details
export const requirePaid = (Component) => {
  return (props) => {
    // Get plan
    const user = useUser();
    const isPaidUser = user.subscriptionStatus === "active" || user.subscriptionStatus === "trialing";
    useEffect(() => {
      console.log("payments.js")
      // Redirect if not signed in
      if (!isPaidUser) {
        history.replace("/pricing");
      }
    }, [user]);


    // Show loading indicator
    // We're either loading (user is null) or we're about to redirect (user is false)
    if (!isPaidUser) {
      return <PageLoader />;
    }

    // Render component now that we have user
    return <Component {...props} />;
  };
};

export const requireSubs = (Component, requirePaid) => {
  return (props) => {
    const user = useUser();
    let auth = useAuth();
    useEffect( () => {
      async function fetchData() {
        let resultData = await getUserSubs(auth,user);
        return resultData;
      }
      if (!user.subs) {
        fetchData();
      }
    }, [user]);

    if (!user.subs) {
      return <PageLoader />;
    }
    if(requirePaid && !user.billing){
      return <PageLoader />;
    }
    if(requirePaid){
      const isPaidUser = user.billing.subscriptionStatus === "active" || user.billing.subscriptionStatus === "trialing";
      if(!isPaidUser){
        history.replace("/pricing");
          return null;
      }else{
        return <Component {...props} />
      }
    }
    else{
      return <Component {...props} />;
    }
  };
};

const getUserSubs = async (auth, user) => {
  if (auth && auth.user && user) {
    try{
      let result = await apiRequest(true,null,null,"getUserData","get");
      result = await result.json();
      await user.handleSubs(result.Subscriptions);
      await user.handleBilling(result);
      if(result!=undefined){
        return result;
      }else{
        console.log("error", "result from getUserSubs is undefined");
      }
    }catch(e){
      if(process.env.NODE_ENV !== "production"){
        console.log("error", e);
      }
    }
  }  
}

 export const clearUserData = async (auth, user) => {
  if (auth && auth.user && user) {
    try{
      await user.handleSubs(null);
      await user.handleBilling(null);
    }catch(e){
      if(process.env.NODE_ENV !== "production"){
        console.log("error", e);
      }
    }
  }  
}
