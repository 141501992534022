import React, { useState,useEffect } from 'react';
import TableWrapper from "./../components/Table";
import NavbarCustom from "./../components/NavbarCustom";
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import '../../node_modules/react-vis/dist/style.css';
import { XYPlot, RadialChart, DiscreteColorLegend } from 'react-vis';
import { useAuth,requireAuth } from '../util/auth';
import { useUser, requireSubs } from '../util/payments';
import { Spinner } from 'react-bootstrap'
import {onSort,toggleSort} from "./../util/subUtils";
import { useRouter } from "./../util/router.js";

var colors = ['#ef5d28', '#0bbd3b', '#850bbd', '#79c7e3', '#f1f507'];
function ReportsPage(props){
  const auth = useAuth();
  const userData = useUser();
  const router = useRouter();
  let subscriptions = userData.subs;
  if(subscriptions===null){
    subscriptions=[];
  }

  const [mode, setMode] = useState("monthly");
  const [isLoading, setIsLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false)
  const [sortState, setSortState] = useState({
    AmountSort: "ascending",
    nameSort: "ascending",
    isInitialSort: true
  });

  const toggleAndSaveSort = async (event, keyName) => {
    let newSortState = toggleSort(sortState,sortState,keyName);
    const sortedSubs = onSort(event, keyName, sortState, [...subscriptions])
    subscriptions = sortedSubs;
    await save();
    setIsLoading(false);
    setSortState(newSortState);
  }


  const loadingCallback = () => {
    setIsLoading(true);
  }

  useEffect(() => {
    if(userData){
      if(subscriptions.length==0){
        router.push("/manage");
      }
    }
  }, []);

  /* useEffect(() => {
    (async () => {
      if(auth && auth.user && isMounted && userData){
          try{
            userData.saveUserData(subscriptions,auth,loadingCallback)
          }catch(e){
            if(process.env.NODE_ENV !== "production"){
              console.log(e);
            }
          }
      }
    })();
    }, [subscriptions]);  */

    const save = async () => {
      if(auth && auth.user && userData){
        try{
          await userData.saveUserSubs(subscriptions,auth,loadingCallback);
          setIsLoading(false);
        }catch(e){
          if(process.env.NODE_ENV !== "production"){
            console.log(e);
          }
        }
      }
  }
  
  const onModeSwitch = selection => {
    if (selection.value == 'Monthly costs') {
      setMode("monthly");
    }
    else if (selection.value == 'Annual costs') {
      setMode("annual");
    }
  }

  const getSubscriptionByMode = (subscriptions, mode) => {
    if (mode == undefined || mode == "") {
      mode = "monthly";
    }
    let result = subscriptions.map((sub, index) => {
      let obj = {};
      obj.name = sub.name;
      if (mode == "annual" && sub.renewal == "Monthly")
        obj.Amount = sub.Amount * 12;
      else if (mode == "monthly" && sub.renewal == "Annual")
        obj.Amount = sub.Amount / 12;
      else {
        obj.Amount = sub.Amount;
      }
      return obj;
    });

    return result;
  }

  const getChartData = subscriptions => {
    let topSubs = [];
    topSubs = subscriptions.sort((a, b) => b["Amount"] - a["Amount"]).slice(0, 5);
    let total = 0;
    topSubs.forEach(function (sub) {
      total += sub.Amount;
    })
    let data = [];
    let chartLegendData = [];
    topSubs.forEach(function (sub, index) {
      let newSubForData = {};
      let newSubForLegendData = {};
      newSubForData.label = sub.name;
      newSubForData.angle = sub.Amount / total;
      newSubForData.color = colors[index];
      newSubForLegendData.title = sub.name===""?"Name is empty":sub.name;
      newSubForLegendData.color = colors[index];
      newSubForLegendData.strokeWidth = 15;
      data.push(newSubForData);
      chartLegendData.push(newSubForLegendData);
    })
    let resultData = {};
    resultData.data = data;
    resultData.chartLegendData = chartLegendData;
    return resultData;
  }

    const options = [
      'Monthly costs', 'Annual costs'
    ]
    let defaultOption = options[0]
   /*  if (subscriptions===undefined || subscriptions.length === 0) {
      window.location = "/manage";
      return;
    } */
    // let mode  = this.props.match.params.mode;
    let modeSubscription = getSubscriptionByMode(subscriptions, mode);
    let chartData = [];
    let chartLegendData = [];
    if (modeSubscription !== undefined) {
      let resultData = getChartData(modeSubscription);
      chartData = resultData.data;
      chartLegendData = resultData.chartLegendData;
    }

    let isAnnual = false;
    if (mode == "annual") {
      defaultOption = options[1]
      isAnnual = true;
    } else if (mode == "monthly") {
      isAnnual = false;
      defaultOption = options[0]
    }
    let loadingContent =<> <div className="SpinnerContainer vertical-center justify-content"><Spinner className="SpinnerMargin" animation="border" role="status"/></div><span className="sr-only">Loading...</span></>
    let mainContent = <>
    <div className="text-center">
    <h5 className="margin-1-all">Top 5 subscription expenses</h5>
    <div id="chartsHolder" className="inline-block justify-content vertical-center">
      <RadialChart className="float-left clear-none" width={350}
        height={350}
        data={chartData}
        colorType={'literal'}
        labelsRadiusMultiplier={0.8}
        labelsStyle={{
          fontSize: 13
        }}
      // showLabels
      />
      <DiscreteColorLegend
        className="float-left clear-none"
        //height="auto"
        width={350}
        items={chartLegendData}
      />
    </div>
  </div>
  <div className="vertical-center margin-1-all">
    <h5 className="inline-block margin-right-1">All subscriptions</h5>
    <Dropdown options={options} className="inline-block" onChange={onModeSwitch} value={defaultOption} placeholder="Select renewal type" />
  </div>
  <TableWrapper
    subscriptionData={subscriptions} isAnnual={isAnnual} toggleAndSaveSort={toggleAndSaveSort} isManageMode={false} sortState={sortState}
  />
  </>; 
  let finalContent = isLoading?loadingContent:mainContent;
    return (
      <>
      <NavbarCustom
        bg="primary"
        variant="light"
        expand="md"
        logo="assets/HappyDebits.png"
      ></NavbarCustom>
      <div className="container">
        {finalContent}
        </div>
      </>
    );
  }

export default requireAuth(requireSubs(ReportsPage));