import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import { useAuth } from "./../util/auth.js";
import Container from "react-bootstrap/Container";
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'

function BillingControlSection(props) {
  const Paddle = window.Paddle;
  const [newPlan, setnewPlan] = useState({ value: 638407, label: 'Happydebits test 2' });
  const options = [
    { value: 638407, label: 'Happydebits test 2' },
    { value: 638370, label: 'Happydebits test' },
    { value: 622077, label: 'Happydebits Business - annual plan' },
    { value: 622076, label: 'Happydebits Business - monthly plan' },
    { value: 622073, label: 'Happydebits Pro - annual plan' },
    { value: 622060, label: 'Happydebits Pro - monthly plan' }
  ]
  let defaultOption = options[0]
  const onPlanSwitch = selection => {
    setnewPlan(selection);
    console.log(selection.value);
  }
  const changePlan = async () => {
    let postData = {};
    postData.paddle_subscription_id = props.userData.paddle_subscription_id
    postData.plan_id = newPlan.value
    let status = false;
    try {
      let res = await props.apiRequest(true, true, postData, "changePlan", "post");
      if (res != undefined) {
        status = true;
      }else{
        status = false;
      }
    } catch (e) {
      if (process.env.NODE_ENV !== "production") {
        console.log("error in change plan", e);
        status = false;
      }
    } finally {
      //setIsLoading(false)
      if(status){
        alert("Plan change request submitted. Please check back in a few minutes.")
      }else{
        alert("There was a problem in processing the plan change. Please contact support@happydebits.com to submit your plan change request.")
      }
    }
  }
  const updatePayment = () => {
    Paddle.Checkout.open({
      override: props.userData.paddle_update_url
    });
  }
  const cancel = () => {
    Paddle.Checkout.open({
      override: props.userData.paddle_cancel_url
    });
  }
  const getPlanNameById = (id) => {
    const result = options.filter(plan => {
      return plan.value===parseInt(id);
    });
    return result[0].label;
  }
  return (
    <Container>
    <table className="margin-bottom"><tbody><tr><td>Your current plan: </td><td>{getPlanNameById(props.userData.subscription_plan_id)}</td></tr>
          <tr><td>Next billing date:</td><td>{props.userData.next_bill_date}</td></tr>
          <tr><td>Subscription status:</td><td>{props.userData.subscriptionStatus}</td></tr>
        </tbody></table>

        <ul className="margin-bottom">
          <li>When changing to a new plan, you will be prorated for the current subscription period.</li>
          <li>You will be charged the amount in difference during the billing, if applicable. </li>
          <li>Plan changes may take a few minutes to get reflected in your account. Please allow a few minutes after updating your plan. You can check this billing page to check if your plan change has been applied. You will also receive the confirmation through email.</li>
          <li>No refunds applicable on cancellation.</li>
        </ul>

        <div className="inline-block justify-content vertical-center margin-bottom-1">

          <div className="margin-right-1">
            <Button variant="primary" size="md" onClick={() => {
              updatePayment()
            }}>
              Update payment details
              </Button>
          </div>
          {/* <div className="margin-right-1">
            <Button variant="secondary" size="md">
              Pause subscription
              </Button>
          </div> */}
          <div className="inline-block">
            <Button variant="danger" size="md" onClick={() => {
              cancel()
            }}>
              Cancel subscription
              </Button>
          </div>
        </div>
        <div className="inline-block justify-content vertical-center">
          <Dropdown options={options} className="margin-right-1 inline-block" onChange={onPlanSwitch} value={newPlan} placeholder="Select renewal type" />
          <div className="margin-right-1 ">
            <Button variant="primary" size="md" onClick={() => {
              changePlan()
            }}>
              Change plan
            </Button>
          </div>
        </div>
        </Container>
  );
}

export default BillingControlSection;
